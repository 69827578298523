.st0 {
    fill: none;
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st1 {
    fill: url(#SVGID_1_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st2 {
    fill: url(#SVGID_2_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st3 {
    fill: url(#SVGID_3_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st4 {
    fill: url(#SVGID_4_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st5 {
    fill: url(#SVGID_5_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st6 {
    fill: url(#SVGID_6_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st7 {
    fill: url(#SVGID_7_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st8 {
    fill: url(#SVGID_8_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st9 {
    fill: url(#SVGID_9_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st10 {
    fill: url(#SVGID_10_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st11 {
    fill: url(#SVGID_11_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st12 {
    fill: url(#SVGID_12_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st13 {
    fill: url(#SVGID_13_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st14 {
    fill: url(#SVGID_14_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st15 {
    fill: #FFFFFF;
}

.st16 {
    fill: #FFFFFF;
    stroke: url(#SVGID_15_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st17 {
    fill: url(#SVGID_16_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st18 {
    fill: url(#SVGID_17_);
}

.st19 {
    fill: url(#SVGID_18_);
}

.st20 {
    fill: url(#SVGID_19_);
}

.st21 {
    fill: url(#SVGID_20_);
}

.st22 {
    fill: none;
    stroke: url(#SVGID_21_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st23 {
    fill: none;
    stroke: url(#SVGID_22_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.7004, 10.0506;
}

.st24 {
    fill: none;
    stroke: url(#SVGID_23_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st25 {
    fill: url(#SVGID_24_);
    stroke: url(#SVGID_25_);
    stroke-miterlimit: 10;
}

.st26 {
    fill: url(#SVGID_26_);
    stroke: url(#SVGID_27_);
    stroke-miterlimit: 10;
}

.st27 {
    fill: #FFFFFF;
    stroke: url(#SVGID_28_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st28 {
    fill: url(#SVGID_29_);
}

.st29 {
    fill: url(#SVGID_30_);
}

.st30 {
    fill: url(#SVGID_31_);
}

.st31 {
    fill: url(#SVGID_32_);
}

.st32 {
    fill: url(#SVGID_33_);
}

.st33 {
    fill: url(#SVGID_34_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st34 {
    fill: none;
    stroke: url(#SVGID_35_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.8062, 10.2093;
}

.st35 {
    fill: none;
    stroke: url(#SVGID_36_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.8062, 10.2093;
}

.st36 {
    fill: none;
    stroke: url(#SVGID_37_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.8062, 10.2093;
}

.st37 {
    fill: none;
    stroke: url(#SVGID_38_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.8062, 10.2093;
}

.st38 {
    fill: url(#SVGID_39_);
}

.st39 {
    fill: #FFFFFF;
    stroke: url(#SVGID_40_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st40 {
    fill: url(#SVGID_41_);
}

.st41 {
    fill: url(#SVGID_42_);
}

.st42 {
    fill: url(#SVGID_43_);
}

.st43 {
    fill: url(#SVGID_44_);
}

.st44 {
    fill: url(#SVGID_45_);
}

.st45 {
    fill: url(#SVGID_46_);
}

.st46 {
    fill: url(#SVGID_47_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st47 {
    fill: none;
    stroke: url(#SVGID_48_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st48 {
    fill: none;
    stroke: url(#SVGID_49_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.6194, 9.9291;
}

.st49 {
    fill: url(#SVGID_50_);
}

.st50 {
    fill: url(#SVGID_51_);
}

.st51 {
    fill: #FFFFFF;
    stroke: url(#SVGID_52_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st52 {
    fill: url(#SVGID_53_);
}

.st53 {
    fill: url(#SVGID_54_);
}

.st54 {
    fill: url(#SVGID_55_);
}

.st55 {
    fill: url(#SVGID_56_);
}

.st56 {
    fill: url(#SVGID_57_);
    stroke: #C9C9C9;
    stroke-width: 2.3527;
    stroke-miterlimit: 10;
}

.st57 {
    fill: none;
    stroke: url(#SVGID_58_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st58 {
    fill: none;
    stroke: url(#SVGID_59_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.5778, 9.8666;
}

.st59 {
    fill: url(#SVGID_60_);
}

.st60 {
    fill: url(#SVGID_61_);
}

.st61 {
    fill: none;
    stroke: url(#SVGID_62_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st62 {
    fill: none;
    stroke: url(#SVGID_63_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.6578, 9.9867;
}

.st63 {
    fill: none;
    stroke: url(#SVGID_64_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st64 {
    fill: url(#SVGID_65_);
}

.st65 {
    fill: url(#SVGID_66_);
}

.st66 {
    fill: none;
    stroke: url(#SVGID_67_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st67 {
    fill: none;
    stroke: url(#SVGID_68_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.7535, 10.1302;
}

.st68 {
    fill: none;
    stroke: url(#SVGID_69_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st69 {
    fill: url(#SVGID_70_);
}

.st70 {
    fill: url(#SVGID_71_);
}

.st71 {
    fill: #FFFFFF;
    stroke: url(#SVGID_72_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st72 {
    fill: url(#SVGID_73_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st73 {
    fill: url(#SVGID_74_);
}

.st74 {
    fill: url(#SVGID_75_);
}

.st75 {
    fill: url(#SVGID_76_);
}

.st76 {
    fill: url(#SVGID_77_);
}

.st77 {
    fill: url(#SVGID_78_);
}

.st78 {
    fill: url(#SVGID_79_);
}

.st79 {
    fill: #FFFFFF;
    stroke: url(#SVGID_80_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st80 {
    fill: url(#SVGID_81_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st81 {
    fill: url(#SVGID_82_);
}

.st82 {
    fill: url(#SVGID_83_);
}

.st83 {
    fill: url(#SVGID_84_);
}

.st84 {
    fill: url(#SVGID_85_);
}

.st85 {
    fill: url(#SVGID_86_);
}

.st86 {
    fill: url(#SVGID_87_);
}

.st87 {
    fill: url(#SVGID_88_);
}

.st88 {
    fill: url(#SVGID_89_);
}

.st89 {
    fill: #FFFFFF;
    stroke: url(#SVGID_90_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st90 {
    fill: url(#SVGID_91_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st91 {
    fill: url(#SVGID_92_);
}

.st92 {
    fill: url(#SVGID_93_);
}

.st93 {
    fill: url(#SVGID_94_);
}

.st94 {
    fill: url(#SVGID_95_);
}

.st95 {
    fill: url(#SVGID_96_);
}

.st96 {
    fill: url(#SVGID_97_);
}

.st97 {
    fill: url(#SVGID_98_);
}

.st98 {
    fill: url(#SVGID_99_);
}

.st99 {
    fill: none;
    stroke: url(#SVGID_100_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st100 {
    fill: none;
    stroke: url(#SVGID_101_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.8401, 10.2602;
}

.st101 {
    fill: none;
    stroke: url(#SVGID_102_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st102 {
    fill: url(#SVGID_103_);
}

.st103 {
    fill: url(#SVGID_104_);
}

.st104 {
    fill: #FFFFFF;
    stroke: url(#SVGID_105_);
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st105 {
    fill: url(#SVGID_106_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st106 {
    fill: url(#SVGID_107_);
}

.st107 {
    fill: url(#SVGID_108_);
}

.st108 {
    fill: url(#SVGID_109_);
}

.st109 {
    fill: url(#SVGID_110_);
}

.st110 {
    fill: url(#SVGID_111_);
}

.st111 {
    fill: url(#SVGID_112_);
}

.st112 {
    fill: none;
    stroke: url(#SVGID_113_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st113 {
    fill: none;
    stroke: url(#SVGID_114_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.614, 9.921;
}

.st114 {
    fill: none;
    stroke: url(#SVGID_115_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st115 {
    fill: url(#SVGID_116_);
}

.st116 {
    fill: url(#SVGID_117_);
}

.st117 {
    fill: none;
    stroke: url(#SVGID_118_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.6918, 10.0376;
}

.st118 {
    fill: none;
    stroke: url(#SVGID_119_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st119 {
    fill: url(#SVGID_120_);
}

.st120 {
    fill: #FFFFFF;
    stroke: url(#SVGID_121_);
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st121 {
    fill: url(#SVGID_122_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st122 {
    fill: url(#SVGID_123_);
}

.st123 {
    fill: url(#SVGID_124_);
}

.st124 {
    fill: url(#SVGID_125_);
}

.st125 {
    fill: url(#SVGID_126_);
}

.st126 {
    fill: url(#SVGID_127_);
}

.st127 {
    fill: url(#SVGID_128_);
}

.st128 {
    fill: #FFFFFF;
    stroke: url(#SVGID_129_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st129 {
    fill: url(#SVGID_130_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st130 {
    fill: url(#SVGID_131_);
}

.st131 {
    fill: url(#SVGID_132_);
}

.st132 {
    fill: url(#SVGID_133_);
}

.st133 {
    fill: url(#SVGID_134_);
}

.st134 {
    fill: url(#SVGID_135_);
}

.st135 {
    fill: url(#SVGID_136_);
}

.st136 {
    fill: url(#SVGID_137_);
}

.st137 {
    fill: none;
    stroke: url(#SVGID_138_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st138 {
    fill: none;
    stroke: url(#SVGID_139_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.8062, 10.2093;
}

.st139 {
    fill: none;
    stroke: url(#SVGID_140_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st140 {
    fill: #FFFFFF;
    stroke: url(#SVGID_141_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st141 {
    fill: url(#SVGID_142_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st142 {
    fill: url(#SVGID_143_);
}

.st143 {
    fill: url(#SVGID_144_);
}

.st144 {
    fill: url(#SVGID_145_);
}

.st145 {
    fill: url(#SVGID_146_);
}

.st146 {
    fill: url(#SVGID_147_);
}

.st147 {
    fill: url(#SVGID_148_);
}

.st148 {
    fill: url(#SVGID_149_);
}

.st149 {
    fill: url(#SVGID_150_);
}

.st150 {
    fill: url(#SVGID_151_);
}

.st151 {
    fill: url(#SVGID_152_);
}

.st152 {
    fill: url(#SVGID_153_);
}

.st153 {
    fill: none;
    stroke: url(#SVGID_154_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.7829, 10.1743;
}

.st154 {
    fill: none;
    stroke: url(#SVGID_155_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st155 {
    fill: url(#SVGID_156_);
}

.st156 {
    fill: none;
    stroke: url(#SVGID_157_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.7045, 10.0567;
}

.st157 {
    fill: none;
    stroke: url(#SVGID_158_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st158 {
    fill: url(#SVGID_159_);
}

.st159 {
    fill: url(#SVGID_160_);
}

.st160 {
    fill: #FFFFFF;
    stroke: url(#SVGID_161_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st161 {
    fill: url(#SVGID_162_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st162 {
    fill: url(#SVGID_163_);
}

.st163 {
    fill: url(#SVGID_164_);
}

.st164 {
    fill: url(#SVGID_165_);
}

.st165 {
    fill: url(#SVGID_166_);
}

.st166 {
    fill: url(#SVGID_167_);
}

.st167 {
    fill: url(#SVGID_168_);
}

.st168 {
    fill: url(#SVGID_169_);
}

.st169 {
    fill: url(#SVGID_170_);
}

.st170 {
    fill: url(#SVGID_171_);
}

.st171 {
    fill: #FFFFFF;
    stroke: url(#SVGID_172_);
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.st172 {
    fill: url(#SVGID_173_);
    stroke: #C9C9C9;
    stroke-width: 4;
    stroke-miterlimit: 10;
}

.st173 {
    fill: none;
    stroke: url(#SVGID_174_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st174 {
    fill: none;
    stroke: url(#SVGID_175_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 6.6093, 9.9139;
}

.st175 {
    fill: none;
    stroke: url(#SVGID_176_);
    stroke-width: 1.6726;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.st176 {
    fill: url(#SVGID_177_);
}

.st177 {
    fill: url(#SVGID_178_);
}

.st178 {
    fill: url(#SVGID_179_);
}

.st179 {
    fill: url(#SVGID_180_);
}

.st180 {
    fill: url(#SVGID_181_);
}

.st181 {
    fill: url(#SVGID_182_);
}

.st182 {
    fill: url(#SVGID_183_);
}

.st183 {
    fill: url(#SVGID_184_);
}

.st184 {
    fill: url(#SVGID_185_);
}

.st185 {
    fill: url(#SVGID_186_);
}